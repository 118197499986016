import React, { useEffect, useRef } from 'react';
import MediaQuery from 'react-responsive';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { media } from '../../../utils/breakpoints/breakpoints';
import Actualites from '../../Actualites/Actualites';
import Avoirs from '../../Avoirs/Avoirs';
import BonsLivraison from '../../BonsLivraison/BonLivraison';
import CallToActionDashboard from '../../CallToActionDashboard/CallToActionDashboard';
import CallToActionTelevente from '../../CallToActionTelevente/CallToActionTelevente';
import CommandeEnCours from '../../CommandeEnCours/CommandeEnCours';
import Commandes from '../../Commandes/Commandes';
import Contact from '../../Contact/Contact';
import DetailCommande from '../../DetailCommande/DetailCommande';
import Error404 from '../../Error404/Error404';
import Factures from '../../Factures/Factures';
import Filtres from '../../Filtres/Filtres';
import Gradient from '../../Gradient/Gradient';
import MentionsLegales from '../../MentionsLegales/MentionsLegales';
import NavigationRapide from '../../NavigationRapide/NavigationRapide';
import ProduitsFavoris from '../../ProduitsFavoris/ProduitsFavoris';
import ResultatsRecherche from '../../ResultatsRecherche/ResultatsRecherche';
import styles from './MainRoutes.module.scss';

export function _MainRoutes({
	location: { pathname, search },
}: RouteComponentProps) {
	const searchParams = new URLSearchParams(search);
	const isSelection = searchParams.has('selection');
	const isRechercheWithoutSelection = !isSelection && pathname === '/recherche';

	// Reset du scroll après chaque rendu
	const scrollableDiv = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (scrollableDiv.current) scrollableDiv.current.scrollTop = 0;
	});

	return (
		<main className={styles.main}>
			{isRechercheWithoutSelection && <Filtres />}

			<Gradient />

			<div className={styles.scrollable} ref={scrollableDiv}>
				{/* CTA sur mobile */}
				<MediaQuery query={media['<laptop']}>
					<Route path="/" exact component={CallToActionTelevente} />
					<Route path="/" exact component={CallToActionDashboard} />
				</MediaQuery>

				{/* Navigation rapide par onglets */}
				<Route
					path={[
						'/commande-en-cours',
						'/commandes',
						'/commandes-favorites',
						'/produits-favoris',
						'/factures',
						'/avoirs',
						'/bonslivraison',
						'/commande/:id',
					]}
					component={NavigationRapide}
				/>

				<Switch>
					<Route path="/recherche" exact component={ResultatsRecherche} />
					<Route path="/" exact component={Actualites} />
					<Route path="/mentions-legales" component={MentionsLegales} />
					<Route path="/commande-en-cours" exact component={CommandeEnCours} />
					<Route path="/commandes" component={Commandes} />
					<Route
						path="/commandes-favorites"
						render={() => <Commandes favorites={true} />}
					/>
					<Route path="/factures" component={Factures} />
					<Route path="/avoirs" component={Avoirs} />
					<Route path="/bonslivraison" component={BonsLivraison} />
					<Route path="/contact" component={Contact} />
					<Route path="/produits-favoris" exact component={ProduitsFavoris} />
					<Route path="/commande/:id" component={DetailCommande} />
					<Route component={Error404} />
				</Switch>
			</div>
		</main>
	);
}

export default withRouter(_MainRoutes);
