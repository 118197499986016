import React from 'react';
import { useTitle } from '../../hooks/useTitle';
import { thinSpace } from '../../utils/french';
import { telephone } from '../../utils/telephone';
import Button from '../Button/Button';
import styles from './Contact.module.scss';
import { ReactComponent as FacebookSVG } from './facebook.svg';
import { ReactComponent as InstagramSVG } from './instagram.svg';

const email = 'televente@vergersdeboulogne.fr';
const adresse = '51 rue de Rouen, 94150 RUNGIS';
const gmap = 'https://goo.gl/maps/m8a2e6zgNT77Q3KFA';
const horaires = '7j/7 – 24h/24';
const site = 'https://les-vergers-de-boulogne.business.site/';
const facebook = 'https://www.facebook.com/Vergers-De-Boulogne-109366940939710';
const instagram = 'https://www.instagram.com/vergersdeboulogne/?hl=fr';

export function Contact() {
	useTitle('Contact');

	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>Nous contacter</h1>

			<p>
				<strong>Par téléphone{thinSpace}:</strong>{' '}
				<a target="_blank" rel="noopener noreferrer" href={'tel:' + telephone}>
					{telephone}
				</a>{' '}
				({horaires})
			</p>

			<p>
				<strong>Par email{thinSpace}:</strong>{' '}
				<a target="_blank" rel="noopener noreferrer" href={'mailto:' + email}>
					{email}
				</a>
			</p>

			<p>
				<strong>Par courrier{thinSpace}:</strong>{' '}
				<a target="_blank" rel="noopener noreferrer" href={gmap}>
					{adresse}
				</a>
			</p>

			<p className={styles.links}>
				<a
					href={site}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.websiteLink}
				>
					<Button outline>Découvrez notre site internet</Button>
				</a>

				<a
					href={facebook}
					target="_blank"
					rel="noopener noreferrer"
					title="Facebook"
					className={styles.social}
				>
					<FacebookSVG />
				</a>

				<a
					href={instagram}
					target="_blank"
					rel="noopener noreferrer"
					title="Instagram"
					className={styles.social}
				>
					<InstagramSVG />
				</a>
			</p>
		</div>
	);
}

export default Contact;
