import formatDate from 'dateformat';
import React from 'react';
import { downloadFile } from '../../../utils/download';
import { isIOS } from '../../../utils/userAgent';
import Button from '../../Button/Button';
import Table from '../../Table/Table';
import { RowProps, TableProps } from '../../Table/types';
import { BonLivraison } from '../BonLivraison';
import styles from './ListeBL.module.scss';

export interface Props {
	error?: string;
	loading: boolean;
	bonslivraison: BonLivraison[];
	selectableRows: NonNullable<TableProps['selectableRows']>;
}

function ListeBL({ error, bonslivraison, loading, selectableRows }: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			selectableRows={!isIOS ? selectableRows : undefined}
			noResultMessage="Vous n'avez aucun bon de livraison"
			columns={[
				{ content: 'Date du bon de livraison' },
				{ content: 'Numéro du bon de livraison', mobileHidden: true },
				{ content: '', center: true, isButton: true },
			]}
			rows={bonslivraison.map(
				({ id, dateBonLivraison, filePath, numeroBonLivraison }) =>
					({
						id,
						mobileTitle: `BonLivraion ${numeroBonLivraison}`,
						cells: [
							{ content: formatDate(dateBonLivraison, 'dd/mm/yyyy') },
							{ content: numeroBonLivraison, className: styles.numero },
							{
								content: (
									<Button
										outline
										title={`Télécharger le bon de livraison ${numeroBonLivraison}`}
										onClick={() => {
											if (isIOS) {
												// Si on n'utilise pas cette méthode, le PWA in-App n'affiche pas de bouton pour fermer le PDF
												// c'est une "nouveauté" de iOS 12, youpi !
												// ah oui, et on peut pas télécharger la facture une fois ouverte...
												// Merci Apple !
												window.open(filePath, '_blank');
											} else {
												downloadFile({
													href: filePath,
													name: numeroBonLivraison,
												});
											}
										}}
									>
										Télécharger
									</Button>
								),
							},
						],
					} as RowProps),
			)}
		/>
	);
}

export default ListeBL;
